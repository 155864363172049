.selectCell {
    width: auto
}

.select {
    width: 100%
}

.imgAction {
    vertical-align: middle;
    cursor: pointer;
    padding-top: 0;
    padding-bottom: 0;
}

.column-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Opcional: Alineación vertical (inicio, centro, fin) */
    align-items: center; /* Opcional: Alineación horizontal (inicio, centro, fin) */
    gap: 10px; /* Opcional: Espacio entre los elementos */
}


.row {
    display: flex;
    flex-direction: row;
}

.colorsRow {
    width: 20px;
    height: 20px;
    margin-left: 5%;
    border: 1px solid #b3b3b3;
}

.backButtonGrid {
    margin-top: 2.5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.modalLoading {
    position: absolute;
    font-size: 35px;
    background-color: white;
    top: 50%;
    left: 50%;
    padding: 30px;
    border-radius: 10%;
    display: flex;
    justify-content: center;
}

.circularProgress {
    align-self: center;
}