.container {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 5%;
}

.container0 {
    display: flex;
    flex-direction: column;
    width: 115px;
    min-width: 115px;
    justify-content: flex-end;
}

.container2 {
    display: flex;
    flex-direction: row;
    margin-top: 2.5%;
    min-width: 1100px;
}

.chromPos {
    display: flex;
    flex-direction: row;
    background-color: #96f68a;
    font-weight: bold;
    width: 100%;
    border: 1px solid grey;
}

.borderContainer {
    border-left: 1px solid grey;
    margin-left: 5%;
}

.chromContainer {
    width: 35px;
    text-align: center;
    padding-left: 2px;
}

.posContainer {
    margin-left: 5%;
}

.headerDiv {
    display: flex;
    flex-direction: column;
    width: 170px;
    min-width: 170px;
}

.roleNameDiv {
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    height: 65px;
    overflow: hidden;
}

.p1Div {
    background-color: #ff7b54;
    width: 50%;
    border-left: 1px solid grey;
    text-align: center;
}

.p2Div {
    background-color: #96f68a;
    width: 50%;
    align-self: center;
    text-align: center;
}

.pQu {
    background-color: #96f68a;
    width: 50%;
    align-self: center;
    text-align: center;
    border-left: 1px solid grey;
}

.pContainer0 {
    display: flex;
    flex-direction: row;
    font-weight: bold;
    justify-content: center;
    margin-top: 5%;
    font-size: 12.6px;
}

.pContainer1 {
    width: 52.5px;
    flex-direction: row;
    display: flex;
    align-self: center;
    border: 1px solid grey;
}

.buttonDiv {
    position: absolute;
    bottom: 15px;
    right: 15px;
}

.wordContainer {
    width: 170px;
    min-width: 170px;
    justify-content: center;
    display: flex;
}

.wordContainer2 {
    width: 52.5px;
    flex-direction: row;
    display: flex;
    align-self: center;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
    border-right: 1px solid grey;
}

.a1Word {
    width: 50%;
    align-self: center;
    text-align: center;
}

.a2Word {
    width: 50%;
    text-align: center;
}

.quaco {
    width: 50%;
    align-self: center;
    text-align: center;
}

.quacoR {
    width: 50%;
    align-self: center;
    text-align: center;
    font-size: 11.5px;
    height: 24.5px;
}

.dataContainer {
    display: flex;
    flex-direction: row;
    font-size: 11.8px;
    min-width: 1100px;
}

.dataContainer2 {
    width: 115px;
    min-width: 115px;
}

.dataContainer5 {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
    border-right: 1px solid grey;
    height: 100%;
}

.chromDiv2 {
    width: 35px;
    text-align: center;
    align-self: center;
    padding-left: 3px;
    font-size: 12px;
}

.posDiv2 {
    margin-left: 5px;
    text-align: center;
    align-self: center;
    font-size: 11.5px;
    width: 52.5px;
}

.dataContainerBorder {
    border-left: 1px solid grey;
    margin-left: 5px;
}

.divFilters {
    margin: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 83%;
    align-self: center;
}

.containerAllele {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 35%;
    height: 135px;
    padding: 30px;
    min-width: 300px;
    border-radius: 8px;
    padding-top: 21px;
    justify-content: center;
    display: flex;
    align-content: center;
    flex-direction: column;
    min-width: 400px;
}

.alleleTitle {
    font-weight: bold;
    font-size: 15px;
    margin-top: 1.5%;
    align-self: center;
}

.alleleDivs {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 65%;
    align-self: center;
    margin-top: 5.5%;
}

.allele {
    border: 1px solid #263b48;
    border-radius: 5px;
    cursor: pointer;
    padding: 20px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 13.5px;
}

.alleleCloseIcon {
    position: absolute;
    top: 6px;
    right: 9px;
    cursor: pointer;
}

.backButton {
    display: flex;
    align-self: flex-start;
}

.modalLoading {
    position: absolute;
    font-size: 35px;
    background-color: white;
    top: 50%;
    left: 50%;
    padding: 30px;
    border-radius: 10%;
    display: flex;
    justify-content: center;
}

.circularProgress {
    align-self: center;
}

.patientTable {
    width: 100%;
    justify-content: center;
    border-collapse: collapse;
    text-align: center;
    border: 3px solid #033170;
}

.embryoContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 15px;
}
.patientTable th, .patientTable td {
    border: 2px solid #033170;
    padding: 10px;
}
.patientTable th {
    background-color: #fdf1f9;
    font-weight: bold;
    font-size: 16px;
}
.patientTable td {
    background-color: #fdf1f9;

}
.titleTable {
    font-size: 18px;
    color: #c3238f;
    background-color: #fdf1f9;
    padding: 10px;
    border: 2px solid #033170;
}
.titleP {
    color: #033170;
    padding-top: 20px;
}

.individualTable {
    width: 100%;
    height: auto;
    text-align: left;
    border-collapse: collapse;
    margin-top: 30px;
}

.individualTable thead {
    color: rgba(125,191,220,1);
}
.individualTable tr {
    border-bottom: 2px solid lightgray;
    border-top: 2px solid lightgray;
}

.pdfcontent {
    position: absolute;
    opacity: 0;
    pointer-events: none; 
    width: 100%;
}

.dataTable {
    text-align: left;
    border-collapse: collapse;
    width: 100%;
    height: auto;
}

.dataTabletd{
    padding-left: 20px;
    border: solid 2px lightgray;
}

.datatdLast {
    padding-left: 20px;
    border-left:  2px solid lightgray;
    border-right:  2px solid lightgray;
}

.name{
    border-top: 2px solid black !important;
    padding-bottom: 30px;
    padding-left: 20px;
    border-left:  2px solid lightgray;
    border-right:  2px solid lightgray;
}

.reportDate{
    border-top: 2px solid black !important;
    text-align: right;
    padding-left: 20px;
    border-left:  2px solid lightgray;
    border-right:  2px solid lightgray;
    border-bottom:  2px solid lightgray;

}

.list {
    list-style-type: none; /* Elimina los puntos predeterminados */
    padding-left: 0; /* Elimina el padding izquierdo */
}

.list li {
    margin-bottom: 10px; /* Espacio entre los elementos */
}

.dash {
    margin-right: 5px; /* Espacio entre el guion y el texto */
    font-weight: bold; /* Puedes ajustar el estilo del guion aquí */
    color: black; /* Cambia el color del guion si es necesario */
}

.divGen {
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 50px;
}

.tableGen {
    display: flex;
    justify-content: center;
    align-items: center;
}

.columnGen {
    width: 100px;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    
}

.headerGen {
    display: flex;
    text-align: center;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 5px;
    border-bottom: 2px solid black; 
    padding-bottom: 5px;
    height: 70px;
}

.label {
    font-weight: bold;
    text-align: center;
    margin-top: 5px;
}

.rowGen {
    display: flex;
    justify-content: space-between;
}

.genAfected {
    width: 100%;
    height: 100%;
    text-align: center;
}

.genNONAfected {
    width: 100%;
    height: 100%;
    text-align: center;

}


.femaleA2 {
    background-color: #32CD32; 
    height: 150px; 
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    width: 50px;
    height: 150px;
}

.maleA1 {
    background-color: #FFD700; 
    height: 150px; 
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    width: 50px;
    height: 150px;
}

.maleA2 {
    background-color: #00CED1; 
    height: 150px; 
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    width: 50px;
    height: 150px;
}

.femaleA1 {
    background-color: #DA70D6; 
    height: 150px; 
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    width: 50px;
    height: 150px;
}

.greyA {
    background-color:rgb(221, 234, 251); 
    height: 150px; 
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    width: 50px;
    height: 150px;
}

.embriocor{
    height: 150px; 
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    width: 50px;
    height: 150px;
    flex-direction: column;
}

.halfColoer{
    height:75px;
    width: 50px;
}