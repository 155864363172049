.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: flex-end;
    margin-right: 0%;
    border: 2.2px solid #6BBBDB;
    border-radius: 6px;
    padding: 12px;
    background-color: #ededed;
    width: 100%;
}

.firstRow {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: column;
}

.firstDiv {
    flex-direction: row;
    display: flex;
    margin-bottom: 10px;
}

.secondDiv {
    flex-direction: row;
    display: flex;
}

.colorItem { 
    width: 28px;
    height: 18px;
    border-radius: 4px;
    background-color: rgb(255, 123, 84);
    align-self: center;
}

.colorItem2 {
    width: 28px;
    height: 18px;
    border-radius: 4px;
    background-color: rgb(153, 153, 153);
    align-self: center;
}

.colorItem3 {
    width: 28px;
    height: 18px;
    border-radius: 4px;
    background-color:#3A3A3A;
    align-self: center;
}

.colorItem4 {
    width: 28px;
    height: 18px;
    border-radius: 4px;
    background-color:#E4A397;
    align-self: center;
}

.textItem {
    font-size: 13px;
    margin-left: 5px;
}

.colorItemText {
    width: 28px;
    height: 18px;
    border-radius: 4.5px;
    background-color: rgb(255, 123, 84);
    align-self: center;
    font-weight: bold;
    font-size: 13px;
    justify-content: center;
    display: flex;
    align-items: center;
}

.colorItemText2 {
    width: 28px;
    height: 18px;
    border-radius: 4.5px;
    background-color: #96f68a;
    align-self: center;
    font-weight: bold;
    font-size: 13px;
    justify-content: center;
    display: flex;
    align-items: center;
}

.colorItemText3 {
    width: 28px;
    height: 18px;
    border-radius: 4.5px;
    background-color: #F2EE81;
    align-self: center;
    font-weight: bold;
    font-size: 13px;
    justify-content: center;
    display: flex;
    align-items: center;
}